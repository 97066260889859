<div class="dividing-section event-section header-level-bannner">
  <div class="container">
    <h1 class="title-heading">My Voice Season 3 Terms & Conditions</h1>
  </div>
</div>

<div class="ads-tab-navigation">
  <div class="dividing-section">
    <div class="container">
      <div class="row yellow_before align-items-top">

        <div class="col-sm-12">
          <div class="content-part-ads">
            <div class="Privacy text-center">
              <h4>&quot;ARE YOU THE NEXT SINGING SUPERSTAR?&quot;</h4>
              <h4>ELIGIBILITY FOR AUDITIONS</h4>

              <p>The Participants must be any Citizens of India/Australia/ New
                Zealand/USA/UK etc.</p>

              <p>The Participants must be of 6 or above the age who can participate after being
                registered</p>


              <h4 class="text-center">MY VOICE SEASON 3 <br>
                REGISTRATION ($40 NON-REFUNDABLE)</h4>

              <p class="text-center">Payment should be done Online while registering.</p>

              <p class="text-center">Terms and Conditions:</p>

              <p class="text-center">Participants should fill the form and pay registration fee, $40 AUD to take
                part in My Voice Season 3</p>
              <p class="text-center">Participants should mention First name (Rosy) and Last name (Mary)
                followed by My Voice in the following format when making payment, as reference</p>
              <p class="text-center">rosy_mary_myvoice</p>
              <p class="text-center">Participants will receive the audition number, only once the full
                payment is received Only registered Participants will be qualified for In house auditions</p>

              <p class="text-center">*Only if you agree to the terms &amp; conditions of My Voice Season 3, please
                register for Auditions* &quot;ALL THE BEST&quot;</p>
              <p class="text-center">
                MY VOICE is a very famous singing reality show across Australia and New Zealand. There is no need for an
                introduction about this show due to its popularity. It is most viewed singing reality show and has
                completed Season 1&amp;2 successfully and now we are going global with Season 3. </p>

              <h4>IMPORTANT NOTE:</h4>
              <ul class="text-left">
                <li>There will be no theme for the auditions round, every participant can pick
                  their favorite song.</li>
                <li>Performers will be judged by independent judges who may or may not be on
                  the Panel of Judges for the finals and also online Voting percentage is also
                  considered.</li>
                <li>Performers will be judged using formal scoring methods for Sur (voice
                  quality), Taal (rhythm) and technical difficulty.</li>
                <li>Judges decision shall be final and binding to all participants</li>
                <li>No challenges/protests shall be entertained.</li>
                <li>The participants will be notified if selected on the Audition day.</li>
                <li>Participants should be willing to travel in between states for finale if selected
                  on there own costs.</li>
              </ul>

              <h4>COMPETITION RULES:</h4>
              <ul class="text-left">
                <li>Participants may sing in All Indian Languages / English</li>
                <li>Participants will have to perform the Mukhda (introductory stanza or
                  chorus) and at least one Antara (verse) of the song</li>
                <li>Every participant should upload 2mins of the video as specified in video
                  requirements.</li>
                <li>These rules are subject to change at the discretion of the organizers.
                  However, any change will be updated on the website and will be
                  communicated to all confirmed participants.</li>
                <li>For all rounds Judges and committee decision will be the final.</li>
              </ul>
              <div class="text-left">
                <p>Details of audition song video upload:</p>
                <ol>
                  <li>Select a song of your choice. Sing that selected song and record the video. The video must
                    be of maximum 2 minutes length.</li>
                  <li>Video of selected recorded songs beyond 2 minutes will not be accepted.</li>
                  <li>The video must be recorded in high definition with video recording of maximum clarity.</li>
                  <li>Video recorded in LANDSCAPE mode is acceptable. Vertical recording will NOT BE
                    ACCEPTABLE.</li>
                  <li>Minimum quality of 780p is expected.</li>
                  <li>Singers are requested to place cameras closer to them. Zooming is not permitted as it may
                    affect your video and voice quality.</li>
                  <li>Select a Calm (no background noise and motion) and Clear background (against a clear wall
                    or door) is permitted. Avoid filling the background with unnecessary objects, moving objects
                    that may distract you.</li>
                  <li>The participants recording video at home or in home settings are advised to record in Calm
                    and Clear background (Clean, plain, and Tidy). The background noise must be avoided at all
                    times.</li>
                  <li>The participants are advised not to use big halls or places with resonance as it may add
                    unnecessary noise which will impact the quality of your song.</li>
                  <li>Use a microphone if needed to ensure further increased voice quality.</li>
                  <li>Avoid holding mobile phone devices in your hand to record. Seek somebody’s help or place
                    it against a stable background.</li>
                  <li>Use of Optical effects are PROHIBITED (e.g., Fast Shooting, Sepia, Black and white Photo
                    etc.).</li>
                  <li>Once recorded according to the above guidelines, UPLOAD the video in cloud (Google Drive
                    or We Transfer) and then give access to Me TV <a
                      href="mailto:contact@metvchannel.com"></a>(Contact@metvchannel.com).</li>

                </ol>
                <p>A Golden Opportunity to reach Global audience and make your dream come true of being at THE
                  WORLD’S STAGE.</p>
                <p>Yes! The registrations are open from 1 September till 20 September. Be Quick to Register as the
                  World awaits to see your talent of singing.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>