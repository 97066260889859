import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../../_services';


@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styles: [
  ]
})
export class LoaderComponent implements OnInit {

  loading: boolean;
  constructor(private loaderService: LoaderService) {

    this.loaderService.isLoading.subscribe((v) => {
      //console.log(v);
      this.loading = v;
    });

  }


  ngOnInit(): void {
  }

}
