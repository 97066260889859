<div class="me-footer">
  <div class="container">
    <div class="main-footer">
      <div class="widget">
        <div class="row">
          <div class="col-lg-3 col-md-3 col-sm-12">
            <h4>ABOUT US</h4>
            <p>Me World is a Media and IT conglomerate with group companies reflecting interests in Television, Media,
              Content Production, Event Management, Digital Marketing and IT Services.</p>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-12">
            <h4>CONTACT US</h4>
            <!-- <p><i class="fa fa-map-marker" aria-hidden="true"></i>&nbsp;30 woornack road carnegie Melbourne, VIC 3163
            </p> -->
            <!-- <p><i class="fa fa-phone" aria-hidden="true"></i>&nbsp;
              123456789</p> -->
            <p><i class="fa fa-envelope-square" aria-hidden="true"></i>&nbsp;
              <a href="mailto:hello@meworldet.com"> hello@meworldet.com</a></p>
            <div class="">
              <ul class="social-icons icon-circle icon-zoom list-unstyled list-inline d-flex justify-content-around">
                <li><a href="https://www.facebook.com/MeWorldET" target="_blank"><i class="fab fa-facebook-f"></i></a>
                </li>

                <li> <a href="https://www.instagram.com/meworldet/" target="_blank"><i class="fab fa-instagram"></i></a>
                </li>

                <li> <a href="https://twitter.com/MeWorldET" target="_blank"><i class="fab fa-twitter"></i></a>
                </li>
                <li> <a href="https://www.youtube.com/channel/UCdbG2JrT2EvwjlgUDr7XCCw" target="_blank"> <i
                      class="fab fa-youtube"></i></a></li>

                <li> <a href="https://www.linkedin.com/company/meworldet" target="_blank"> <i
                      class="fab fa-linkedin"></i></a></li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-6 col-6">
            <h4>QUICK LINKS</h4>
            <ul class="list">
              <li><a href="#" routerLink="/home">Home</a></li>
              <li><a href="#" routerLink="/home/about">About Us</a></li>
              <li><a href="#" routerLink="/home/metv">Me Tv</a></li>
              <li><a href="#" routerLink="/home/news">News</a></li>
              <li><a href="#" routerLink="/home/events">Events </a></li>
              <li><a href="#" routerLink="/home/production">Production </a></li>
              <!--<li><a href="#">Gallery</a></li>-->
            </ul>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-6 col-6">
            <h4>QUICK LINKS</h4>
            <ul class="list-extra">
              <li><a href="#" routerLink="/home/advertising">Advertising </a></li>
              <li><a href="#" routerLink="/home/offers">Offers </a></li>
              <!-- <li><a href="#" routerLink="/home/earnpoints">Earn Points</a></li> -->
              <li><a href="#" routerLink="/home">Blog</a></li>
              <li><a href="#" routerLink="/home/privacy-policy">Privacy Policy</a></li>
              <li><a href="#" routerLink="/home/terms-conditions">Terms and Conditions</a></li>
              <!-- <li><a href="#">Sitemap</a></li> -->
              <li><a href="#" routerLink="/home/contact-us">Contact Us</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="footer-bottom">
  Copyright All Reserved 2020 - Me World
  <!-- | Developed by <a href="http://www.nidhula.com/">Nidhula 
  </a>-->
</div>