import { Component, OnInit } from '@angular/core';
import { globalPath } from '../_globals'

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styles: []
})
export class PageNotFoundComponent implements OnInit {

  constructor() { }
  globalpath = globalPath

  ngOnInit() {
  }

}
