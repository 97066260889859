import { Injectable } from '@angular/core';
import {
  HttpResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoaderService, AlertService } from '../_services';
import { environment } from '../_environment';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {

  disableHttpurl = environment.apiurl + "videos_events/notification_video_events";
  disableHttpurlWatch = environment.apiurl + "videos_events/watch_list";


  private requests: HttpRequest<any>[] = [];

  constructor(private loaderService: LoaderService, private alertService: AlertService) { }

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.loaderService.isLoading.next(this.requests.length > 0);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.requests.push(req);

    // console.log("No of requests--->" + this.requests.length);
    // console.log(req['url']);
    // console.log(this.disableHttpurl);

    // switch (req['url']) {
    //   case this.disableHttpurl: {
    //     //statements; 
    //     break;
    //   }
    //   case this.disableHttpurlWatch: {
    //     //statements; 
    //     break;
    //   }
    //   default: {
    //     this.loaderService.isLoading.next(true);
    //     break;
    //   }
    // } 


    if (req['url'] != this.disableHttpurl) {
      this.loaderService.isLoading.next(true);
    }



    return Observable.create(observer => {
      const subscription = next.handle(req)
        .subscribe(
          event => {
            if (event instanceof HttpResponse) {
              this.removeRequest(req);
              observer.next(event);
            }
          },
          err => {
            this.removeRequest(req);
            observer.error(err);
            this.alertService.error(err.error.code + " =>> Server Side Issue (or) Please check you Internet");
          },
          () => {
            this.removeRequest(req);
            observer.complete();
          });
      // remove request from queue when cancelled
      return () => {
        this.removeRequest(req);
        subscription.unsubscribe();
      };
    });
  }
}
