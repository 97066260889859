import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule, pages } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { GlobalModule } from './global/global.module';
import { AlertComponent } from './alert/alert.component';
import { HttpInterceptorService } from './_interceptors/http-interceptor.service';
import { LoaderService, MessagingService } from './_services';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SafePipe } from './_pipes/safe.pipe';



import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import {
  GoogleLoginProvider
} from 'angularx-social-login';



import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { AsyncPipe } from '../../node_modules/@angular/common';
import { AboutMobileViewComponent } from './about-mobile-view/about-mobile-view.component';
import { PrivacyPolicyMobileViewComponent } from './privacy-policy-mobile-view/privacy-policy-mobile-view.component';
import { TermsConditionsMobileViewComponent } from './terms-conditions-mobile-view/terms-conditions-mobile-view.component';
import { OnlinebrandingMobileViewComponent } from './onlinebranding-mobile-view/onlinebranding-mobile-view.component';
import { UrlService } from './_services/url.service';
import { AdsenseModule } from 'ng2-adsense';




@NgModule({
  declarations: [
    AppComponent, pages,
    AlertComponent,
    SafePipe,
    AboutMobileViewComponent,
    PrivacyPolicyMobileViewComponent,
    TermsConditionsMobileViewComponent,
    OnlinebrandingMobileViewComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    NgbModule,
    BrowserAnimationsModule,
    HttpClientModule,
    GlobalModule,
    MatSnackBarModule,
    SocialLoginModule,
    AngularFireModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AdsenseModule.forRoot({
      adClient: 'ca-pub-7123584263809819',
      adSlot: 6210968095,
    }),
    AppRoutingModule,
  ],

  providers: [MessagingService, UrlService,
    LoaderService, { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true }, {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '1034744711214-6ao6mks7q27fertu0ugbt39bl89vf0ba.apps.googleusercontent.com'
            ),
          },
        ],
      } as SocialAuthServiceConfig,
    }],
  bootstrap: [AppComponent]
})

export class AppModule { }
