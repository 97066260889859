<div class="dividing-section event-section header-level-bannner">
  <div class="container">
    <h1 class="title-heading">Privacy<span>Policy</span></h1>
  </div>
</div>

<div class="ads-tab-navigation">
  <div class="dividing-section">
    <div class="container">
      <div class="row yellow_before align-items-top">

        <div class="col-sm-12">
          <div class="content-part-ads">
            <div class="Privacy">
              <h4>1. OVERVIEW</h4>
              <p> In this policy, "us", "we" or "our" means ME WORLD (ABN 45643880335, Me tv - 82615303663) and its
                related bodies corporate
                (ME World & Me TV.... Email id - <a href="mailto:hello@meworldet.com">hello@meworldet.com</a>).
                Your privacy matters to us whether you are new to our service or are a long time user. Please read this
                policy carefully as its set out the way we handle your "personal information".
                ME WORLD, is an Australia-based Media and IT Conglomerate with interests in Broadcasting, Digital
                Marketing, Advertising, Digital Community Creation and IT Services.
                ME WORLD APP is our tool that weaves together and brings onto a single platform the ME WORLD family of
                companies in an easy to use App Interface that delivers the services of ME TV, ME Entertainment, ME
                Digital, ME IT and ME Marketing.<br>

                We are bound by the Australian Privacy Principles contained in the Privacy Act 1988 (Cth) ("Act").</p>
              <h4>2. ACCOUNT SECURITY</h4>

              <p> We use security measures to protect the loss, misuse, and unauthorized alteration of the information
                under
                our control. Please be advised, however, that we cannot guarantee that our security measures will
                prevent
                disruptions or unauthorized access from occurring.<br>
                You are solely responsible for (a) maintaining the security of your account(s) and (b) all activity that
                occurs under your account(s). You must notify us immediately if you suspect any unauthorized access to
                or
                use of your account(s). If you forget your account login information, you may request that we send it to
                the email address associated with your account(s).</p>

              <h4> 3. INFORMATION WE COLLECT</h4>
              <p> We may collect the following types of personal information:</p>
              <ul>
                <li>name;</li>
                <li>mailing or street address;</li>
                <li>email address;</li>
                <li>telephone number and other contact details;</li>
                <li>age or your date of birth;</li>
                <li>credit card information;</li>
                <li>details of the products and services you have purchased from us or which you have enquired about,
                  together with any additional information necessary to deliver those products and services and to
                  respond
                  to your enquiries;</li>
                <li>any additional information relating to you that you provide to us directly through our websites or
                  indirectly through use of our website or online presence through our representatives or otherwise, or
                  through other websites or accounts from which you permit us to collect information;</li>
                <li>information you provide to us through our service centre, customer surveys or visits by our
                  representatives from time to time;</li>
                <li>any other personal information which may be required in order to facilitate your dealings with us.
                </li>

              </ul>

              <h4> 4. TRACKING TECHNOLOGIES AND THIRD-PARTY DATA COLLECTION</h4>
              <p> We ask LOCATION (current location) permission from user to display the content
                based on his current location.<br>
                We ask CAMERA permission from user to give them a provision to upload their Profile Image.</p>
              <h4>5. HOW WE USE YOUR INFORMATION</h4>

              <ul>
                <li>Fulfill your orders and deliver video programming and other services to you;</li>
                <li>Provide customer support to you;</li>
                <li>Analyze how our services are being used;</li>
                <li>Communicate with you;</li>
                <li>Verify your eligibility to make purchases and participate in offers;</li>
                <li>Collect any debts, prevent fraud, and otherwise protect the integrity of our systems;</li>
                <li>Enforce our legal rights;</li>
                <li>Perform any functions described at the time of collection; and</li>
                <li>Otherwise operate our business.</li>
              </ul>

              <h4>6. DISCLOSURES TO THIRD PARTIES</h4>
              <p>
                ME WORLD only shares personal information with other companies or individuals outside of ME WORLD in the
                following limited circumstances:</p>

              <ul>
                <li>We have your consent. We require opt-in consent for the sharing of any sensitive personal
                  information.</li>
                <li>If we provide such information to our subsidiaries, affiliated companies or other trusted businesses
                  or
                  persons for the purpose of processing personal information on our behalf. We require that these
                  parties
                  agree to process such information based on our instructions and in compliance with this Privacy Policy
                  and
                  any other appropriate confidentiality and security measures.</li>
                <li>We have a good faith belief that access, use, preservation or disclosure of such information is
                  reasonably necessary to (a) satisfy any applicable law, regulation, legal process or enforceable
                  governmental request, (b) enforce applicable Terms of Service, including investigation of potential
                  violations thereof, (c) detect, prevent, or otherwise address fraud, security or technical issues, or
                  (d)
                  protect against harm to the rights, property or safety of ME WORLD, its users or the public as
                  required or
                  permitted by law.</li>
              </ul>
              <p>
                If ME WORLD becomes involved in a merger, acquisition, or any form of sale of some or all of its assets,
                we will ensure the confidentiality of any personal information involved in such transactions and provide
                notice before personal information is transferred and becomes subject to a different privacy policy.
              </p>

              <h4>7. COOKIES</h4>
              <p>
                We ask LOCATION (current location) permission from user to display the content based
                on his current location.
              </p>
              <h4>8. CHILDREN'S PRIVACY</h4>
              <p> We do not knowingly permit any person who is under 18 years of age to register or to provide any other
                personally identifying information. Please do not register with us if you are below 18 years of age. If
                we
                become aware that any Personal Information of persons less than 18 years of age has been collected on
                the
                Platform without verified parental consent, then we will take the appropriate steps to delete any such
                information.<br>
                While persons under the age of 18 may utilize the ME WORLD services, they may do so only with the
                involvement, supervision, and approval of a parent or legal guardian.</p>

              <h4>9. THIRD-PARTY SERVICES</h4>

              <h4>10. DISPUTE RESOLUTION</h4>
              <p>We are committed to working with you to obtain a fair resolution of any complaint. We encourage you to
                begin by contacting our Data Protection Officer. We endeavour to respond to requests promptly.</p>

              <h4>11. CONTACT US</h4>

              <p> Address<br>
                ME WORLD values the trust placed by users and therefore, we follow the highest standards to protect your
                Personal Information.<br>
                If you require any information or clarification regarding the use of your Personal Information (defined
                below) or this Privacy Notice or if you require any general information or clarification or inquiry
                regarding the Platform please email us at XXXX</p>


              <h4> 12. CHANGES TO THIS PRIVACY POLICY</h4>

              <p> Please note that this Privacy Policy may change from time to time. We will not reduce your rights
                under
                this Privacy Policy without your explicit consent. We will post any Privacy Policy changes on this page
                and, if the changes are significant, we will provide a more prominent notice (including, for certain
                services, email notification of Privacy Policy changes).</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>