<div class="dividing-section event-section header-level-bannner">
  <div class="container">
    <h1 class="title-heading">Terms & Conditions</h1>
  </div>
</div>

<div class="ads-tab-navigation">
  <div class="dividing-section">
    <div class="container">
      <div class="row yellow_before align-items-top">

        <div class="col-sm-12">
          <div class="content-part-ads">
            <div class="Privacy">
              <h4>1 Overview</h4>

              <p>
                1.1. ME WORLD, is an Australia-based Media and IT Conglomerate with interests in Broadcasting, Digital
                Marketing, Advertising, Digital Community Creation and IT Services.<br>
                ME WORLD APP is our tool that weaves together and brings onto a single platform the ME WORLD family of
                companies in an easy to use App Interface that delivers the services of ME TV, ME Entertainment, ME
                Digital, ME IT and ME Marketing.</p>

              <p> 1.2. Users of a ME WORLD App (users or you) agree to be bound by these terms and conditions (Terms).
                These
                Terms apply to each ME WORLD Services you subscribe to and your use of, and access to, a ME WORLD
                indicates your acceptance of these Terms (and any changes to them).</p>


              <h4> 2. Agreement</h4>

              <p> 2.1 This Agreement governs your use of the ME WORLD services (including your use of the ME WORLD App
                and/or ME WORLD Website) and your use of the Software). By creating an account to access the ME WORLD
                Service ("Account") you agree to be bound by the terms of this Agreement.</p>

              <p> 2.2 This Agreement incorporates:<br>
                (a) your Account details;<br>
                (b) these terms and conditions;<br>
                (c) our Privacy Policy</p>

              <p> 2.3 We may change the terms of this Agreement at any time. We will notify you of any material change
                prior
                to the date such change comes into effect. If any such change has a materially detrimental effect on
                you,
                you may cancel your subscription in accordance with clause.</p>

              <p> 2.4 You can contact us at any time to obtain a copy of the current version of this Agreement.</p>

              <p> 2.5 This Agreement continues month to month unless you cancel or we terminate this Agreement in
                accordance
                with the terms of this Agreement.</p>


              <h4> 3. Eligibility and Access</h4>

              <p> 3.1 To create an Account which will allow you to subscribe to the Me World Service, you must: <br>
                (a) be at least 18 years of age;<br>
                (b) provide us with your email address;<br>
                (c) provide us with the details of your valid credit/debit card (“Valid Card”) or PayPal account (each a
                "Payment Method"); and<br>
                (d) have an Australian residential address and provide us with your postcode.</p>

              <p> 3.2 To access the ME WORLD Service, you must:<br>
                (a) set up an Account;<br>
                (b) have a Supported Device that is connected to the internet in Australia;<br>
                (c) install the ME WORLD App (if necessary); and<br>
                (d) subject to the specific terms of any Promotional Offer (as defined below), pay the monthly
                Subscription Fee (as defined below) in accordance with clause 6 below.</p>


              <h4> 4. Subscription Plans</h4>

              <p> 4.1 ME WORLD offers different subscription plans for the ME WORLD Service (“Subscription Plan(s)”). A
                different monthly subscription fee (“Subscription Fee”) applies to each Subscription Plan. Information
                on
                the inclusions within each Subscription Plan is available at ME WORLD.com.au</p>

              <p> 4.2 You can choose your Subscription Plan when you create your Account.</p>

              <p> 4.3 If you already have an Account then you can change your Subscription Plan by visiting my.ME
                WORLD.com.au.</p>

              <p> 4.4 If you change your Subscription Plan we will commence charging your Payment Method the then
                current
                Subscription Fee for the Subscription Plan you are using at the commencement of your next monthly
                subscription period in accordance with clause 6 below.</p>


              <h4> 5. Promotional Offers</h4>

              <p> 5.1 Your access to the Me World Service including your Subscription Plan may include a free trial
                period
                or other promotional discount or benefit ("Promotional Offer"). We reserve the right to offer, withdraw,
                change, cancel or determine your eligibility for any Promotional Offer in our absolute discretion.<br>
                Promotional Offers may be subject to terms and conditions which shall apply in addition to, and prevail
                to
                the extent of any inconsistency with, this Agreement. We may charge for Promotional Offers based on the
                then current monthly Subscription Fee for your Subscription Plan if we consider that the Promotional
                Offer
                is being abused.</p>

              <p>5.2 Free trials are open to new subscribers only.</p>

              <p> 5.3 It is your responsibility to know:<br>
                (a) when your free trial period ends; and<br>
                (b) the applicable Subscription Fee for your Subscription Plan.
              </p>

              <p> 5.4 We will commence billing you the monthly Subscription Fee for your then current Subscription Plan
                in
                accordance with clause 6, unless you cancel your Account before the end of the free trial period.</p>


              <h4> 6 Payment</h4>

              <p> 6.1 By creating your Account and providing us with the details of your Payment Method, you authorise
                us to
                charge your Payment Method the Subscription Fee for your chosen Subscription Plan in accordance with
                this
                clause 6.</p>

              <p> 6.2 The Subscription Fee for your Subscription Plan will be charged to your Payment Method in advance
                of
                each monthly subscription period.</p>

              <p> 6.3 Your first monthly subscription period starts when you<br>
                (a) create your Account, or if your Account commences with a free trial period, immediately after expiry
                of your free trial period<br>
                (b) pay your first monthly Subscription Fee from your Payment Method.</p>

              <p> 6.4 At the end of the first monthly subscription period and each monthly subscription period
                thereafter,
                we will automatically renew your monthly subscription and charge your Payment Method the monthly
                Subscription Fee unless and until you cancel your Account. If you created your Account on the 29th or
                30th
                of the month, your Payment Method will be charged the monthly Subscription Fee on the anniversary of
                such
                date in each month except for February, where your Payment Method will be charged the monthly
                Subscription
                Fee on the last day of the month. If you created your Account on the 31st of the month, your Payment
                Method will be charged the monthly Subscription Fee on the last day of each month.</p>

              <p> 6.5 Unless expressly stated otherwise, all Subscription Fees are GST inclusive. If your chosen Payment
                Method is your Valid Card, to ensure your Valid Card is not being used fraudulently, we may validate
                your
                Valid Card with a small pre-authorisation. This will result in the funds available on your Payment
                Method
                account being reduced by this amount. These funds are held by your card issuer. It may take some days
                for
                the validation amount to be re-adjusted on your Payment Method account.</p>


              <p> 6.6 We may increase the Subscription Fee for the Subscription Plan you are currently subscribed to at
                any
                time by giving you at least one calendar months' notice except where such increase is required by law or
                any regulatory authority (in which case we will try to give you reasonable notice).</p>

              <p> 6.7 If we are unable to collect the Subscription Fee from your Payment Method for any reason,
                including,
                without limitation, expiration or insufficient funds, you remain responsible for any uncollected amounts
                and we may suspend or cancel your access to the ME WORLD Service without giving you notice.</p>

              <p> 6.8 If we are able to collect the Subscription Fee from your Payment Method during a period of
                suspension
                under clause 6.7 (including without limitation where you provide us with new or updated details of your
                Payment Method) we will reinstate your access to the ME WORLD Service and your new monthly subscription
                period will commence on the date we successfully charge your Payment Method.</p>


              <h4> 7. Classification </h4>

              <p> 7.1 We will provide classification information as required by law with each piece of applicable
                Content
                available on the ME WORLD Service. You accept full responsibility for reviewing all classification
                information supplied for each piece of Content for the purpose of informing, and where appropriate
                safeguarding, other viewers of the Content, including children. You can restrict access to Content based
                on its classification using the "Profiles" feature in your Account settings</p>

              <p> 7.2 You are responsible for the security of your Account PIN.</p>

              <p> 7.3 You are responsible for ensuring that Content classified as MA15+ is only viewed or accessed by
                persons 15 years and over and Content classified as R18+ is only viewed or accessed by persons 18 years
                and over.</p>


              <h4> 8. Suspension and Cancellation</h4>

              <p> 8.1 You may cancel your Account at any time. If your Account commenced with a free trial and you
                cancel
                your Account during the free trial period, cancellation is effective immediately and your Account will
                be
                closed and your access to the ME WORLD Service will be disconnected from the date you cancel. If your
                Account did not commence with a free trial or you cancel your Account at any time after the end of your
                free trial, cancellation is effective at the end of the current monthly subscription period and your
                Account will be closed and your access to the ME WORLD Service will be disconnected from this date. You
                will not receive a refund of any fees or other amounts already paid to us when you cancel</p>

              <p> 8.2 If you wish to cancel as a result of us changing this Agreement in a way that has a materially
                detrimental effect on you, you must notify us within 14 days of our notice to you about that change.
                Your
                cancellation will be effective as of the date of the change to this Agreement, and you will receive a
                pro-rata refund for any amount already paid to us in respect of any period after that date.</p>

              <p> 8.3 We may cancel this Agreement or your Account at any time. Except as set out in clause 8.4 below,
                if we
                cancel this Agreement or your Account, you will be able to access the ME WORLD Service for the remainder
                of the current monthly subscription period unless we cancel this Agreement or your Account during the
                free
                trial period (if any), in which case, we will cancel your Account and your access to the ME WORLD
                Service
                immediately.</p>

              <p> 8.4 We may immediately suspend, restrict or cancel your Account or access to the ME WORLD Service:
                <br>
                (a) where reasonably necessary for security, technical, copyright, anti-piracy or operational
                reasons;<br>
                (b) if you use the Content or the ME WORLD Service other than for private, non-commercial use, or in a
                way
                that is inconsistent with this Agreement or the requirements of our content partners or licensors;<br>
                (c) if you breach any term of this Agreement; or<br>
                (d) if we have reasonable grounds to suspect that your Account details are incorrect, there has been
                unauthorised access to your Account, or that you have committed or may be committing any illegal or
                fraudulent activity in connection with your Account.
              </p>


              <h4> 9. Intellectual Property</h4>
              <p> 9.1 All content on or available through the ME WORLD Service (including the Content, and all other
                works,
                subject matter and other material comprising or available on the ME WORLD Website and the ME WORLD Apps)
                and the Software is our property or, as applicable, the property of our content partners or licensors.
                That property is protected by, and your use of the ME WORLD Service and Software is subject to, all
                relevant intellectual property laws.</p>

              <p> 9.2 We take the infringement of copyright and other intellectual property rights very seriously. We
                reserve the right to take all steps necessary to prevent the unauthorised copying, distribution,
                reproduction, transmission, communication or exploitation of the Content or the Software.</p>

              <p> 9.3 ME WORLD is a registered trademark of ME WORLD Entertainment Pty Ltd. ME WORLD's graphics and
                logos
                are trademarks of ME WORLD Entertainment Pty Ltd. ME WORLD's trademarks may not be used in conjunction
                with any product or service other than the ME WORLD Service. All other trademarks that appear on the ME
                WORLD Website and/or the ME WORLD Apps that are not owned by ME WORLD Entertainment Pty Ltd are the
                property of their respective owners.</p>

              <p> 9.4 We may permit you to post or upload content, information or other material to your Account or in
                connection with the ME WORLD Service. You may not post or upload anything which is illegal, obscene,
                threatening, defamatory, which infringes any person's rights (including without limitation intellectual
                property rights) or is otherwise objectionable to us. By posting any such material, you grant us a
                non-exclusive, perpetual, irrevocable, royalty-free licence to use, copy, display, sublicense, modify,
                adapt and communicate that material to the public.</p>

              <h4> 10. Liability</h4>

              <p> 10.1 To the maximum extent permitted by law (but subject to clause 11.2): <br>
                (a) the ME WORLD Service and all Content and Software and any other features or functions associated
                with
                the ME WORLD Service, are provided "as is" and "as available" with all faults and without warranty;<br>
                (b) we do not make any promises or assurances to you about the ME WORLD Service, the Content or the
                Software, including without limitation that your use of the ME WORLD Service or the Software will be
                uninterrupted or error-free;<br>
                (c) we make no representations or warranties in relation to the accuracy or completeness of the
                information, advice or other content available on or via the ME WORLD Service, the ME WORLD Website or
                the
                ME WORLD App and we do not accept any liability in relation to your reliance on such information, advice
                or other content;<br>
                (d) all other terms, conditions and warranties, whether express or implied by legislation or the common
                law or otherwise relating to the provision by us of the ME WORLD Service or otherwise in connection with
                this Agreement are expressly excluded;<br>
                (e) we will not be liable for any loss or damage (including indirect or consequential loss or damage,
                loss
                of profit, loss of revenue, loss of data or loss of opportunity) however caused which may be suffered or
                incurred or which may arise directly or indirectly in respect of the use by you of the ME WORLD Service
                (including the ME WORLD Website and the ME WORLD App).</p>

              <p> 10.2 Nothing in this Agreement excludes, restricts or modifies any rights that you have under existing
                laws or regulations and codes, including the Competition and Consumer Act 2010 (Cth) and fair trading
                laws. The ME WORLD Service comes with statutory guarantees under consumer protection laws that cannot be
                excluded.</p>

              <p> 10.3 You are responsible for all acts and omissions of any person who uses your ME WORLD Service.</p>

              <p> 10.4 You indemnify us and our shareholders, directors, officers, employees, suppliers, content
                partners
                and licensors (each an "indemnified party") from and against any loss, damage, liability, costs,
                expenses
                or other liability the indemnified party suffers or incurs arising out of or in connection with any
                claim
                or demand against us by you or any person other than you, which arises from or is connected with: <br>
                (a) our supply, suspension, restriction or cancellation of the ME WORLD Service or your Account; or <br>
                (b) your use of the ME WORLD Service, including in connection with any content, information or other
                material posted on the ME WORLD Website or App from your Account, or any other information you provide
                to
                us,
                unless the loss, damage, liability, cost, expense or other liability is caused by the relevant
                indemnified
                party's wilful default, negligence or breach of this Agreement.
              </p>


              <h4> 11. Privacy</h4>

              <p> 11.1 We collect, store, use and disclose personal information in accordance with our Privacy Policy.
                By
                creating an Account, you consent to us collecting, storing, using and disclosing your personal
                information
                in accordance with our Privacy Policy.</p>

              <h4> 12. General</h4>
              <p> 12.1 We may transfer any or all of our rights and/or obligations under this Agreement to any person,
                firm
                or company provided such transfer does not detrimentally affect your rights under this Agreement.
              </p>
              <p> 12.2 You must not transfer any or all of your rights and/or obligations under this Agreement to any
                other
                person.</p>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>