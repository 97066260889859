import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { fadeAnimation } from './_animations';
import { filter } from 'rxjs/operators';
import { Router, NavigationEnd, ActivatedRoute, RoutesRecognized, RouterOutlet } from '@angular/router';
import { MessagingService, } from './_services';
import { UrlService } from './_services/url.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from './_environment';

import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  animations: [fadeAnimation]
})
export class AppComponent implements OnInit {


  baseurl = environment.baseurl;



  previousUrl: string = null;
  currentUrl: string = null;

  title = 'meEntertainment';
  message;

  constructor(private messagingService: MessagingService, private router: Router, private urlService: UrlService, private modalService: NgbModal, @Inject(PLATFORM_ID) private _platformId: Object,) {
    if (isPlatformBrowser(this._platformId)) {
      console.log("me world working with universal");
    }
  }

  public getRouterOutletState(outlet: RouterOutlet) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }

  ngOnInit() {
    this.messagingService.requestPermission();
    this.messagingService.receiveMessage();
    this.message = this.messagingService.currentMessage;

    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.previousUrl = this.currentUrl;
      this.currentUrl = event.url;
      this.urlService.setPreviousUrl(this.previousUrl);
      localStorage.setItem("previousUrl", this.previousUrl);
    });
  }

}
