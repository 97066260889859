<div class="ads-tab-navigation">
  <div class="dividing-section">
    <div class="container">
      <div class="row yellow_before align-items-top">

        <div class="col-sm-12">
          <div class="content-part-ads">
            <div class="Privacy">
              <h1 class="text-center" style="font-size: 30px">My Voice Season 3 - GOING GLOBAL<br>
                GRAND PRIZE of 15,000 AUD to be won!<br>
                CASH CASH CASH!!
              </h1><br>
              <img src="assets/img/mvs_poster.jpg" class="mvs-poster" alt="">
              <br>
              <p>“Music is a symphony of the soul.” One of the best ways to enjoy music is by singing. Singing takes
                talent just like any other art-form to become professionals. My Voice concept is based on finding new
                singing talents drawn from public auditions who are waiting for an opportunity. The CoronaVirus
                pandemic has tied all of us to our homes, so My Voice III auditions are coming to your homes.
                Everything at your doorstep, your comfort!! </p>
              <p>After two Grand Successful Seasons (MY VOICE 1 and MY VOICE 2), we are back with a bigger and better
                Global My Voice Season -3. Yes, WE ARE BACK BIGGER & BOLDER!!</p>
              <p>The applications are invited from across the globe. Anybody from any part of the world can
                participate...What a great opportunity knocking on your door!! This is a fully digital music
                competition, having live performance for the Grand Finale (subject to Covid restrictions).</p>
              <p>Singers from around the world can participate by sending their entries which can be in ALL Indian
                Languages and in English. The submitted entries will be evaluated, screened and judged by a panel of
                incredible judges. The selected entries (shortlisted by judges) will start competing for THE WINNING
                TITLE.</p>


              <p><strong> <u> How Can I participate??</u> </strong></p>
              <p>Register in the link below and send us your performance. It is a click away. Please read the
                guidelines below carefully to understand the process, rules, rewards and other benefits..</p>
              <p><strong> <u> Who Can Participate??</u> </strong></p>
              <p>All ages and gender are welcome!! If you believe that you have talent or can sing, then come on board
                for My Voice Season -3. You need not be residing in Australia to participate. You can be in any part
                of the world, speaking either English or any Indian language to register and <strong> win the Grand
                  Prize of
                  $15,000</strong> as it is a <strong> Global Competition.</strong> </p>


              <p><strong> <u> What is the Registration Fee ?</u></strong></p>
              <p>Participants should fill the form and pay registration fee, $40 AUD to take part in My Voice Season
                3.</p>


              <p><strong> <u> Which Category Should I Apply For??</u></strong></p>
              <p>My Voice – 3 has four categories:</p>
              <p>Silver Category (6-14 years of age) </p>
              <p>Golden Category (15 – 23 years of age)</p>
              <p>Diamond Category (24 - 40 years of age)</p>
              <p>Platinum Category (40+ years of age).</p>

              <p><strong> <u> What Language Do I Select for My Song?? </u></strong></p>
              <p>You can sing in any Indian language or in English as we are going “Global” in this Grand My Voice
                Season 3. </p>
              <p>Register in the link below and let the world see your talent!! </p>




              <p><strong> <u> How Can I Send My Video Recorded Song??</u></strong></p>
              <p>Details of audition song video upload:</p>
              <ol>
                <li>Select a song of your choice. Sing that selected song and record the video. The video must be of
                  maximum 2 minutes length.</li>
                <li>Video of the selected recorded song beyond 2 minutes will not be accepted.</li>
                <li>The video must be recorded in high definition with video recording of maximum clarity.</li>
                <li>Video recorded in LANDSCAPE mode is acceptable. Vertical recording will NOT BE ACCEPTABLE.</li>
                <li>Minimum quality of 780p is expected.</li>
                <li>Singers are requested to place cameras closer to them. Zooming is not permitted as it may affect
                  your video and voice quality.</li>
                <li>Select a Calm (no background noise and motion) and Clear background (against a clear wall or door)
                  is permitted. Avoid filling the background with unnecessary objects, moving objects that may
                  distract you.</li>
                <li>The participants recording video at home or in home settings are advised to record in Calm and
                  Clear background (Clean, plain, and Tidy). The background noise must be avoided at all times.</li>
                <li>The participants are advised not to use big halls or places with resonance as it may add
                  unnecessary noise which will impact the quality of your song.</li>
                <li>Use a microphone if needed to ensure further increased voice quality.</li>
                <li>Avoid holding mobile phone devices in your hand to record. Seek somebody’s help or place it
                  against a stable background.</li>
                <li>Use of Optical effects are PROHIBITED (e.g., Fast Shooting, Sepia, Black and white Photo etc.).
                </li>
                <li>Once recorded according to the above guidelines, UPLOAD the video in cloud (Google Drive or We
                  Transfer) and then give access to Me TV <a
                    href="mailto:contact@metvchannel.com">(Contact@metvchannel.com)</a>.</li>
              </ol>
              <p><strong><i>A Golden Opportunity to reach Global audience and make your dream come true of being at
                    THE WORLD’S STAGE.</i></strong></p>
              <p><strong> <u> What are the dates of Registration??</u></strong></p>
              <p>The registrations are open <strong><i> from 1 September till 10 October 2021.</i></strong> </p>
              <p>Be Quick to Register as the World awaits to see your talent of singing. </p>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>