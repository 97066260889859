
import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../_environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class MessagingService {

  fcmSurverKey = "AAAAPT0c0Ns:APA91bEI-td4KXR5xqAY4UHkPn5Npcs-20f7ZdsgfbIfPGK6DNEHruht8fqR2gY3OY10Z8RjRLq-io0Js8hLRYw-0-XUZEiYt-DSyvGOY95FUUfXg-sR4Baw7ObuvYA0hHkHMHLT8aHQ";

  currentMessage = new BehaviorSubject(null);

  constructor(private angularFireMessaging: AngularFireMessaging, private http: HttpClient) {
    this.angularFireMessaging.messaging.subscribe(
      (msgings) => {
        msgings.onMessage = msgings.onMessage.bind(msgings);
        msgings.onTokenRefresh = msgings.onTokenRefresh.bind(msgings);
      })
  }

  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        console.log("token enabled");
        this.subscribeTokenToTopic(token, 'MeWorld');
      });
  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe((payload) => {
      console.log("show message!", payload);
      this.currentMessage.next(payload);
      // this.showCustomNotification(msg);
    })
  }

  updateFCMToken(token) {
    var formdata = new FormData();
    formdata.append('User_ID', localStorage.getItem('User_ID'));
    formdata.append('Access_Token', token);
    formdata.append('Type', "web");
    return this.http.post(environment.apiurl + 'users/update_token', formdata);
  }


  subscribeTokenToTopic(token, topic) {

    const headers = new HttpHeaders().set('Authorization', `key=${this.fcmSurverKey}`);

    return this.http.post('https://iid.googleapis.com/iid/v1/' + token + '/rel/topics/' + topic, {}, { headers: headers }).subscribe(res => {
      console.log(`${topic} topic subscribed`);
    })

  }

}
