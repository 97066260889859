<div class="dividing-section">
  <div class="container">
    <div class="row yellow_before align-items-center">
      <div class="col-sm-8">
        <div class="content-part-ads">
          <h1 class="title-heading">Online Branding</h1>
          <div class="content-text">
            A brand development process goes beyond ideation. Digital presence forms an integral component of
            the overall marketing mix to reach out to a tech-savvy millennials audience. Our bouquet of
            offerings will help you to connect with your core audience and weave a seamless brand narrative and
            recall across platforms.
          </div>
          <!-- <div class="text-left">
            <button class="btn btn-earnpoint" (click)="adsPOP(participates, '')">
              Contact Us
            </button>
          </div> -->
        </div>
      </div>
      <div class="col-sm-4">
        <img alt="Online Branding" src="{{ globalpath }}assets/img/me_online_branding.svg" class="ads-img" />
      </div>

      <div class="col-sm-12 mt-5">
        <div class="row">
          <div class="col-sm-6 ad_why_choosepart"><img alt="SEO" src="assets/img/me_seo.svg">
            <div class="why-title my-3">SEO</div>
            <div class="why-content">
              SEO is a technical, analytical and creative process to increase the visibility of your website in
              search engines portals like Google, Yahoo, Bing etc. Our goal is to driving more traffic of users
              on your website by adding more suitable Meta Keywords and Meta Description on your website.
              <ul class="mt-3">
                <li>Analyzing your website.</li>
                <li>R &amp; D of your competitor website.</li>
                <li>Finding targeted keywords.</li>
                <li>Adding Meta Description, Title etc.</li>
                <li>Taking measures to increase the traffic to your website through search engines.</li>
                <li>Increasing your search engine rankings.</li>
                <li>Provide reports on progress, which helps you to change or modify your business strategies.
                </li>
              </ul>
            </div>
          </div>
          <div class="col-sm-6 ad_why_choosepart"><img alt="SMO" src="assets/img/me_smo.svg">
            <div class="why-title my-3">SMO</div>
            <div class="why-content">
              SMO is similar to SEO where the primary target is to achieve traffic to the website. Using social
              media in an effectively way can be one of the greatest assets for improving organic search results
              of the website. Connecting with people by various social media can be extend traffic of your
              website and it will increase more opportunities for your business. SMO increases the awareness of
              your products, business, brand or event etc. by using number of social media outlets & communities
              like:
              <ul class="mt-3">
                <li>Facebook</li>
                <li>Twitter</li>
                <li>Google+</li>
                <li>LinkedIn</li>
                <li>Vimeo</li>
                <li>Flicker</li>
                <li>MySpace</li>
                <li>You Tube</li>
                <li>Diigo etc</li>
              </ul>
            </div>
          </div>
          <div class="col-sm-6 ad_why_choosepart"><img alt="ONLINE ADVERTISING" src="assets/img/me_online.svg">
            <div class="why-title my-3">ONLINE ADVERTISING</div>
            <div class="why-content">
              Every business depends on the advertising, promotions and marketing manager plan to generate
              interest of customers in your product and brand which is important for every business for their
              growth. According to today's trends, online advertising is more important to populate your brand.
              Traditionally, we use print & electronic media for ad campaigns; similarly we provide the internet
              based advertising. In this we targeting the user traffic to your website through two popular way :
              <ul class="mt-3">
                <li>Google Ads.</li>
                <li>Facebook Ads</li>
              </ul>
            </div>
          </div>
          <div class="col-sm-6 ad_why_choosepart"><img alt="EMAIL MARKETING" src="assets/img/me_email.svg">
            <div class="why-title my-3">EMAIL MARKETING</div>
            <div class="why-content">
              Email Marketing is the type of direct marketing .It use electronic mail as an effective way of
              communications. It is an efficient way to stay connected with your clients while also promoting
              your business. It is easy and cheapest way to start a conversation with your customers. It is a
              one-to-one relationship, to connect the customer directly to your business. However, it is usually
              used to refer as like:<br>
              Analyzing the targeted customers.
              <ul class="mt-3">
                <li>Creating great-looking designed e-mail.</li>
                <li>Sending bulk- emails by gathering traffic with the purpose of acquiring new customers
                  immediately.</li>
                <li>Tracking the results &amp; responses on offers.</li>
                <li>Email allows marketers to reach out to consumers with personalized, relevant, dynamic
                  messages.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>