import { Component, OnInit } from '@angular/core';
import { globalPath } from "../_globals";

@Component({
  selector: 'app-onlinebranding-mobile-view',
  templateUrl: './onlinebranding-mobile-view.component.html',
  styles: [
  ]
})
export class OnlinebrandingMobileViewComponent implements OnInit {

  constructor() { }
  globalpath = globalPath;

  ngOnInit(): void {
  }

}
