import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { AlertService } from '../_services';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styles: [
  ]
})
export class AlertComponent implements OnInit {
  private subscription: Subscription;
  message: any;



  constructor(private alertService: AlertService, private _snackBar: MatSnackBar) { }

  openSnackBar(message, className) {
    this._snackBar.open(message, 'Close', {
      duration: 5000,
      panelClass: [className]
    });
  }

  ngOnInit() {
    this.subscription = this.alertService.getAlert()
      .subscribe(message => {
        switch (message && message.type) {
          case 'success':
            this.openSnackBar(message.text, 'snakbar-success');
            break;
          case 'error':
            this.openSnackBar(message.text, 'snakbar-danger');
            break;
        }

        this.message = message;
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }


}
