// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCyJgSILVFcrLcvjqndPu4zLIEr0sAkfjk",
    authDomain: "meworld-2483d.firebaseapp.com",
    databaseURL: "https://meworld-2483d.firebaseio.com",
    projectId: "meworld-2483d",
    storageBucket: "meworld-2483d.appspot.com",
    messagingSenderId: "263018303707",
    appId: "1:263018303707:web:ee3ce56951ccb83ba03cfe",
    measurementId: "G-9PQMECG187"
  },
  hostUrl: 'https://meworldet.com/',
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
