import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

import { AboutMobileViewComponent } from './about-mobile-view/about-mobile-view.component';
import { PrivacyPolicyMobileViewComponent } from './privacy-policy-mobile-view/privacy-policy-mobile-view.component';
import { TermsConditionsMobileViewComponent } from './terms-conditions-mobile-view/terms-conditions-mobile-view.component';
import { OnlinebrandingMobileViewComponent } from './onlinebranding-mobile-view/onlinebranding-mobile-view.component';
import { MvsPageContentComponent } from './mvs-page-content/mvs-page-content.component';
import { MvsTermsconditionsComponent } from './user/mvs-termsconditions/mvs-termsconditions.component';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./user/user.module').then(mod => mod.UserModule),
    //canActivate: [AuthGuard]
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module')
      .then(m => m.AuthModule),
  },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'about-mobile-view', component: AboutMobileViewComponent },
  { path: 'privacy-policy-mobile-view', component: PrivacyPolicyMobileViewComponent },
  { path: 'terms-conditions-mobile-view', component: TermsConditionsMobileViewComponent },
  { path: 'online-branding-mobile-view', component: OnlinebrandingMobileViewComponent },
  { path: 'mvs-page-mobile-view', component: MvsPageContentComponent },
  { path: 'mvs-terms-condition-mobile-view', component: MvsTermsconditionsComponent },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
  })],
  exports: [RouterModule]
})

export class AppRoutingModule { }

export const pages = [PageNotFoundComponent, MvsPageContentComponent]
