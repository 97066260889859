<main [@fadeAnimation]="getRouterOutletState(o)">
  <router-outlet #o="outlet"></router-outlet>
</main>

<!-- <div style="position: fixed; top: 0px; left: 0px; z-index: 999;">
  {{message | json}}
</div> -->


<app-loader></app-loader>

<app-alert></app-alert>