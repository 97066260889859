<div class="dividing-section event-section header-level-bannner">
  <div class="container">
    <h1 class="title-heading">About<span>us</span></h1>
  </div>
</div>

<div class="ads-tab-navigation">
  <div class="dividing-section">
    <div class="container">
      <div class="row yellow_before align-items-top">

        <div class="col-sm-12">
          <div class="content-part-ads">
            <h1 class="title-heading">ME WORLD</h1>
            <div class="content-text">Me World is a Media and IT conglomerate with group companies reflecting interests
              in Television, Media, Content Production, Event Management, Digital Marketing and IT Services. </div>

            <h1 class="title-heading">ME WORLD APP</h1>
            <div class="content-text">ME WORLD APP is our tool that weaves together and brings onto a single platform
              the ME WORLD family of companies in an easy to use App Interface that delivers the services of ME TV, ME
              Entertainment, ME Digital, ME IT and ME Marketing.</div>


            <h1 class="title-heading">ME TV</h1>
            <div class="content-text">Founded in Australia in 2016, ME TV is a trail blazer aiming to cater to the needs
              of the South Asian immigrant communities around the world.<br>

              ME TV is a digital platform that creates the space and opportunity for community building, immigrant
              community integration with mainstream society as well as generates opportunities for members of the
              community to showcase their talents and achievements.</div>

            <h1 class="title-heading">ME NEWS & ANALYSIS</h1>
            <div class="content-text">Want to get local news or wish to get an analysis of global developments? Here is
              where you can get coverage of the most important matters that will impact your everyday life.<br>
              Want to know what is happening in the world in your own language? Tune in to listen to the top
              commentators
              and opinion makers from the South Asian community.</div>

            <h1 class="title-heading">ME EVENTS & CONTENT</h1>
            <div class="content-text">We have a successful track record of organizing large scale cultural events with
              celebrities. ETV Jabardasth, BIGG BOSS Debate with Kaushal Manda and Babu Gogineni and such shows that we
              organized were a
              phenomenal success. We also undertake line production and content created by us has crossed the million
              views milestone mark.<br>

              If you are planning to organize a social, cultural or business event however small or big, we can support
              you by organizing it for you by taking care of the logistics, or provide event coverage Do get in
              touch!<br>

              It you wish to book tickets for an event, you can pay securely and reserve your place through our ME WORLD
              APP.</div>

            <h1 class="title-heading">ME TALENT</h1>
            <div class="content-text">Are you or your family members or friends keen to share with the world your
              special skills or interests?<br>
              Then get in touch with us, and let us know what makes you special. We will discuss with you how best to
              get recognition for your abilities.</div>

            <h1 class="title-heading">ME MARKETING & ADVERTISEMENT</h1>
            <div class="content-text">Reaching a niche market or a targeted audience in the digital age is a
              specialist task. We provide the insight and expertise to provide the ideal digital marketing mix you need
              to reach your market. <br>

              We are happy to propose Advertisement Strategies and Campaign proposals as well as deploy them with our
              cutting edge ME WORLD APP.
            </div>

            <h1 class="title-heading">ME REWARDS</h1>
            <div class="content-text">Thank you for visiting! Stay with us and earn generous reward points which you can
              redeem for lower prices when shopping with ME APP. You can also gift your points to your friends and
              family! <br>

              <ul>
                <li>DOWNLOAD our ME WORLD APP and earn XXX points.</li>
                <li>INVITE your friends to join ME WORLD and earn XXX points.</li>
                <li>SHARE our content and earn XXX points</li>
                <li>PARTICIPATE in our Polls or give feedback and earn points.</li>
              </ul>

            </div>

            <h1 class="title-heading">ME ETHICS</h1>
            <div class="content-text">We are committed to a better society. We treat our staff and partners with
              professionalism, efficiency and courtesy.<br>

              All advertisements and offerings made via ME APP will have to meet minimum standards of ethical
              advertising.<br>
              We take special care that only those goods, products and services offered via our platform would be
              beneficial to our community and are in the spirit of the law.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>